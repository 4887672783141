import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi, I am <span className="purple">Sirius Ganesh </span>
            from <span className="purple"> Nashville, TN.</span>
            <br />
            <br />
            I am a <span className="purple">Systems Consultant </span> and <span className="purple">Robotics Engineer </span>
            at <span className="purple"> Geek+ Robotics</span>
            <br />
            <br />
            I attended the <span className="purple">University of Illinois Urbana-Champaign </span> where I majored in <span className="purple">Engineering Mechanics </span>
            with a minor in  <span className="purple">Business </span>
            <br />
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity" style={{ marginBottom: "8px" }}>
              <ImPointRight /> <span className="purple">Autocross Racing</span> - 2009 LS3 C6
            </li>
            <li className="about-activity" style={{ marginBottom: "8px" }}>
              <ImPointRight /> <span className="purple">Espresso</span> - Lelit MaraX w/ DF64 GenII
            </li>
            <li className="about-activity" style={{ marginBottom: "8px" }}>
              <ImPointRight /> <span className="purple">RC Aviation</span> - Large scale EDF Jet aircraft and collective pitch helicopters
            </li>
          </ul>
          <br />
          <br />
          <p style={{ color: "rgb(155 126 172)" }}>
            “Where Curiosity Meets Craft and Discipline Drives Success.”{" "}
          </p>
          <footer className="blockquote-footer">Sirius</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
