import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiJira,
  SiGithub,
  SiConfluence,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMacos aria-label="macOS" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode aria-label="Visual Studio Code" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman aria-label="Postman" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSlack aria-label="Slack" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel aria-label="Vercel" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJira aria-label="Jira" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGithub aria-label="GitHub" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiConfluence aria-label="Confluence" />
      </Col>
    </Row>
  );
}

export default Toolstack;