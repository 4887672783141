import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Robotics Engineer",
          "Systems Consultant",
          "Espresso Aficionado", // Corrected spelling
          "Autocross Racer",
          "Enthusiast Pilot",
          "Aspiring MBA Candidate",
        ],
        autoStart: true,
        loop: true,
        delay: 10, // Typing speed
        deleteSpeed: 25, // Deletion speed
        pauseFor: 1000, // Pause between typing
      }}
    />
  );
}

export default Type;