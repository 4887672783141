// import React from "react";
// import { Col, Row } from "react-bootstrap";
// import { DiJavascript1, DiPython, DiMysql, DiGit } from "react-icons/di";
// import { FaRobot, FaServer } from "react-icons/fa"; // Added for ROS and REST API

// function Techstack() {
//   return (
//     <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
//       <Col xs={4} md={2} className="tech-icons">
//         <DiPython /> {/* Python */}
//       </Col>
//       <Col xs={4} md={2} className="tech-icons">
//         <DiJavascript1 /> {/* JavaScript */}
//       </Col>
//       <Col xs={4} md={2} className="tech-icons">
//         <DiMysql /> {/* MySQL */}
//       </Col>
//       <Col xs={4} md={2} className="tech-icons">
//         <DiGit /> {/* Git */}
//       </Col>
//       <Col xs={4} md={2} className="tech-icons">
//         <FaRobot /> {/* Placeholder for ROS */}
//       </Col>
//       <Col xs={4} md={2} className="tech-icons">
//         <FaServer /> {/* Placeholder for RESTful API */}
//       </Col>
//     </Row>
//   );
// }

// export default Techstack;

import React from "react";
import { Row, Col } from "react-bootstrap"; // Ensure react-bootstrap is installed
import { DiPython, DiJavascript1, DiMysql, DiGit } from "react-icons/di";
import { FaRobot, FaServer } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython aria-label="Python" /> {/* Python */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 aria-label="JavaScript" /> {/* JavaScript */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql aria-label="MySQL" /> {/* MySQL */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit aria-label="Git Version Control" /> {/* Git */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaRobot aria-label="Robot Operating System (ROS)" /> {/* ROS */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaServer aria-label="RESTful API" /> {/* RESTful API */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftexcel aria-label="Microsoft Excel" /> {/* Excel */}
      </Col>
    </Row>
  );
}

export default Techstack;