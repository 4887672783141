import React from "react";
import Typewriter from "typewriter-effect";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/7V3A7997.svg";
import Tilt from "react-parallax-tilt";
import { FaEnvelope } from "react-icons/fa";
import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlinePhone,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Let me <span className="purple"> Introduce </span> Myself
            </h1>
            <p className="home-about-body">
              Driven by a passion for robotics and warehouse automation, I have spearheaded innovative solutions as both a
              <i><b className="purple"> Robotics Engineer </b></i> and <i><b className="purple">Systems Consultant</b></i>.
              In these roles, I’ve <i><b className="purple">led cross-functional teams</b></i> to optimize automation processes,
              introducing cutting-edge technologies that <i><b className="purple">improved picking efficiency by 350% </b></i>
              and <i><b className="purple">reduced operational costs by 30%</b></i>. By leveraging <i><b className="purple">data analysis </b></i>
              and strategic insights, I successfully identified key inefficiencies and implemented solutions that streamlined workflows
              across multiple warehouses. My focus on <i><b className="purple">continuous improvement</b></i> not only enhanced operational performance
              but also empowered teams to adopt new technologies, fostering a culture of innovation and adaptability within the organizations I’ve worked with.
              <br />
              <br />
              <br />

              I've collaborated with industry-leading organizations such as
              <br />
              <div className="typewriter-container">
                <i>
                  <b className="purple">
                    <Typewriter
                      options={{
                        strings: [
                          "Walmart",
                          "UPS",
                          "ZARA",
                          "Sephora",
                          "Honda",
                          "Columbia Sportswear",
                          "SKIMS",
                          "Milwaukee Tool",
                          "Tractor Supply",
                          "Happy Returns"
                        ],
                        autoStart: true,
                        loop: true,
                        delay: 10,
                        deleteSpeed: 25,
                        pauseFor: 1000,
                      }}
                    />
                  </b>
                </i>
              </div>
              <br />
              where I led strategic initiatives in robotics and warehouse automation.
              These partnerships enabled me to implement transformative solutions that enhanced
              operational efficiency and delivered measurable business outcomes.
              <br />
              <br />
              <br />
              My interests lie in developing <i><b className="purple">scalable, tech-driven solutions</b></i>, with a particular focus on <i><b className="purple">Artificial Intelligence</b></i>, <i><b className="purple">Warehouse Robotics</b></i>, and <i><b className="purple">Conversational AI Platforms</b></i>. I am passionate about leveraging these technologies to optimize business operations and drive innovation across industries.
              <br />
              <br />
              <br />
              Outside of automation, I’m often experimenting with coffee, continually refining my technique to pull the perfect <i><b className="purple">espresso shot</b></i>, or pushing my limits on the track as an <i><b className="purple">autocross racer</b></i>—both pursuits that fuel my drive for precision and performance.
              <br />
              <br />
              <br />
              As an aspiring <i><b className="purple">MBA candidate</b></i> with a focus on <i><b className="purple">Management</b></i>, <i><b className="purple">Finance</b></i>, and <i><b className="purple">Disruptive Technologies</b></i>, I’m eager to expand my business acumen and strategically lead the development of innovative solutions that will shape the future of business.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Connect with me</h1>
            <p>
              I'd love to <span className="purple">chat </span>with you about anything and everything
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/siriusganesh"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:siriusganesh@outlook.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaEnvelope />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+14699291465"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlinePhone />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/siriusganesh/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/siriusganesh"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
